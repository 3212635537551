import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';
import useStyles from './styles';

const References = ({ pageContext }) => {
  const { title, items } = pageContext;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.wrapper}>
        {items.map(({ id, location, phrase, texts, info }) => (
          <Item key={id} id={id} location={location} phrase={phrase} texts={texts} info={info} />
        ))}
      </div>
    </div>
  );
};

References.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        location: PropTypes.string.isRequired,
        phrase: PropTypes.string.isRequired,
        texts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        info: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default References;
export { default as Head } from '@/components/Head';
