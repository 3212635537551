import React from 'react';
import PropTypes from 'prop-types';

const MartinStiftungMeilen = ({ className }) => (
  <svg viewBox="0 0 136 16" className={className}>
    <defs>
      <clipPath id="j9idu7uz6a">
        <path d="M1024 0v1463H0V0h1024z" />
      </clipPath>
      <clipPath id="redq5rtzzb">
        <path d="M4.005 0c.366 0 .71.04 1.033.122.322.081.613.211.874.39.253.177.454.406.605.688.151.283.226.624.226 1.022 0 .36-.05.678-.152.954a1.962 1.962 0 0 1-.453.717 2.685 2.685 0 0 1-.66.49 4.85 4.85 0 0 1-.85.351c.428.61.783 1.123 1.067 1.54.283.416.63.922 1.042 1.518.18.264.342.457.486.583.145.124.289.226.432.305.105.057.238.101.397.132.16.03.291.048.397.052v.467H6.113A58.789 58.789 0 0 0 4.711 7.07a52.614 52.614 0 0 0-1.359-1.966h-.888v2.959c0 .154.022.288.067.405.045.115.14.204.288.265.073.031.201.06.385.086.183.027.338.042.465.046v.467H0v-.467c.098-.009.24-.024.425-.046s.315-.05.388-.086a.539.539 0 0 0 .266-.25.929.929 0 0 0 .083-.42V1.315c0-.153-.02-.29-.061-.408C1.06.79.964.7.813.637A3.635 3.635 0 0 0 0 .468V0zm-.502.54h-1.04v3.997h.802c.562 0 1.03-.193 1.406-.58.375-.388.563-.914.563-1.58 0-.564-.151-1.011-.453-1.342C4.48.705 4.053.539 3.503.539z" />
      </clipPath>
      <clipPath id="tv340akicc">
        <path d="m3.185 0 .257.146v2.128h1.36c.195.178.165.812-.107.941H3.442V7.08c0 1.136.423 1.364.695 1.364.21 0 .467-.049.725-.195l.21.568-1.449.73c-.316.163-.452.196-.618.196-1.193 0-1.933-.552-1.933-2.127v-4.4H.15L0 2.955l.483-.682h.589V1.202L3.185 0z" />
      </clipPath>
      <clipPath id="3u4q4d05od">
        <path d="M7.614 0v9.336H5.996L1.538 2.687v6.649H0V.014h1.591l4.498 6.69V0h1.525z" />
      </clipPath>
      <clipPath id="e1jdalf5ae">
        <path d="M3.444 0c1.104 0 1.97.426 2.268.626l-.669 1.466c-.347-.226-.793-.346-1.276-.346-.508 0-.769.267-.769.573 0 .52.732.946 1.574 1.786C5.687 5.225 5.96 5.93 5.96 6.904c0 .826-.632 2.745-3.234 2.745C1.71 9.65.694 9.396 0 8.796L.793 7.25c.372.306.868.653 1.487.653.52 0 1.09-.28 1.09-.973 0-.746-.842-1.293-1.524-1.932C.942 4.145.41 3.652.41 2.505.409 1.013 1.809 0 3.444 0z" />
      </clipPath>
      <clipPath id="34m83osdbf">
        <path d="M2.752 0v2.232c0 .302-.027.568-.078.8h1.53v1.622H2.753v2.482c0 .478.195.717.587.717h.866v1.623h-.866c-.658 0-1.154-.128-1.487-.383-.48-.373-.72-1.038-.72-1.993V4.654H0V3.031h.255c.34 0 .571-.065.693-.197.122-.13.183-.38.183-.746V0h1.62z" />
      </clipPath>
      <clipPath id="j3lsvqxh6g">
        <path d="M1.032 0c.58 0 1.033.488 1.033 1.11 0 .623-.453 1.11-1.033 1.11C.453 2.22 0 1.734 0 1.11 0 .489.453 0 1.032 0z" />
      </clipPath>
      <clipPath id="aeh5jv2m1h">
        <path d="M2.87 0v4.928c0 .623.126.894.97.894v.542H0v-.542c.906 0 1.033-.27 1.033-.894V1.435C1.033.812.906.542.025.542V.054L2.87 0z" />
      </clipPath>
      <clipPath id="p64870v7ki">
        <path d="M7.96 0v2.95h-1.7V1.616H3.587v2.215H5.51v1.615H3.587V7.95h1.368v1.616H0V7.95h1.272V1.616H0V0h7.96z" />
      </clipPath>
      <clipPath id="v33s2272bj">
        <path d="M3.544 0v6.356c0 .485.086.848.261 1.091.174.243.433.363.777.363.338 0 .68-.115 1.03-.349.348-.232.702-.578 1.06-1.038V1.267H5.496V0h3.543v8.136h1.179v1.268H6.673V7.63c-.356.662-.782 1.159-1.28 1.49a3.02 3.02 0 0 1-1.71.497c-.784 0-1.398-.262-1.841-.788-.442-.525-.663-1.25-.663-2.176V1.267H0V0h3.544z" />
      </clipPath>
      <clipPath id="ia4edqxsyk">
        <path d="M3.894.063s1.188-.142 3.558 0l1.853-.06.528 9.217-2.098.241.192-4.532s-.837 1.4-1.869 2.7c0 0-.01.007-.032.018l-.09.05c-.177.096-.525.283-.93.485-.362.182-.77.376-1.137.529l-1.188.06s-.81.023-.981-.162c0 0-.75-.496-1.11-.92 0 0-.475-.9-.59-1.55v-.086c.006-.315.04-1.437.264-2.398l.016-.036c.1-.225.656-1.444 1.238-2.264 0 0 1.518-.994 2.376-1.292zM7.5 1.26l-1.346.029c-1.558.042-2.587.27-2.587.27-.911.596-1.676 1.49-1.676 1.49-.185.369-.413 1.097-.413 1.097-.393.823-.129 1.7-.129 1.7.106.326.634.752.634.752.449.227 1.122.327 1.122.327.633-.114 1.748-.655 1.987-.774l.047-.023c.902-.63 1.518-2.1 1.518-2.1l.936-2.669-.093-.099z" />
      </clipPath>
      <clipPath id="4cfhpkjdwl">
        <path d="M11.314 0v15.713H0V0h11.314z" />
      </clipPath>
      <clipPath id="runhh3nt8m">
        <path d="M5.247 2.531C6.01 1.267 7.078-.419 8.637.095A.543.543 0 0 1 8.822.1c.823.178 1.005 1.218 1.334 1.892.464.954.985 1.925 1.073 3.021.065.793-.015 1.578.013 2.37.018.545.096 1.096.065 1.64-.067 1.162-.165 2.324-.53 3.428-.687 2.083-2.565 2.874-4.427 3.22-.279.052-.557.046-.835.026l-.555-.047a4.17 4.17 0 0 0-.558-.01 5.279 5.279 0 0 1-1.345-.081c-.874-.168-2.072-.33-2.804-.887-.42-.319-.31-1.115.277-1.097.719.023 1.412.454 2.118.615.927.21 1.815.146 2.75.223 1.523.125 3.495-.531 4.183-2.136.361-.844.356-1.674.48-2.563.128-.905-.035-1.731-.01-2.636.022-.806.077-1.607-.09-2.388-.047 1.202-.27 2.386-.814 3.462-.351.696-1.19 1.49-1.99 1.419-.883-.08-1.337-.656-1.878-1.363-.608-.794-.714-1.908-.623-2.904.045-.5.119-.966.25-1.448.11-.404.125-.967.341-1.326zm3.16-1.275c-.53-.102-.81.212-1.199.524-.342.26-.608.65-.812 1.105a.293.293 0 0 0-.005.012c-.448 1.005-.597 2.322-.563 3.225.05 1.327 1.53 3.179 2.351 1.24.287-.677.511-1.507.623-2.338.062-.555.077-1.082.055-1.612a5.09 5.09 0 0 0-.039-.352 10.923 10.923 0 0 0-.376-1.716.753.753 0 0 0-.034-.088z" />
      </clipPath>
      <clipPath id="foz7l5t27n">
        <path d="m.664 0 2.194.183s-.225 1.033-.434 2.172l-.069.382c-.056.32-.109.64-.153.94 0 0-.052 1.144-.038 1.944v.087c.001.186.015.638.101 1.066l.057.14H0L.664 0z" />
      </clipPath>
      <clipPath id="7hjisfeeqo">
        <path d="M.594 0s.774.018 1.244.137l.138.074-.29 1.546-.857.12s-.511-.016-.774-.18H0z" />
      </clipPath>
      <clipPath id="5affxh6xop">
        <path d="M.743.007c.312-.06.529.298.55.335.01.018.017.038.022.058l.181.768a.249.249 0 0 1 .006.054v.219c.055-.144.09-.23.094-.238a.204.204 0 0 1 .02-.04c.366-.547.857-.765.878-.774C3.24.072 4.13.181 4.146.184c.643.121 1.215.62 1.239.64.694.618.933 1.74.943 1.788a.193.193 0 0 1 .005.036c.033.674.086 1.649.107 2.048l.008.134.002.048c.022 1.87-.084 3.31-.085 3.324l-.226 1.497a.208.208 0 0 1-.186.181l-.63.046H5.31a.196.196 0 0 1-.154-.076.23.23 0 0 1-.042-.19c.118-.54.147-1.55.152-2.263v-.41a25.366 25.366 0 0 0-.004-.392 119.722 119.722 0 0 1-.263-2.573c-.126-1.124-.857-1.705-.951-1.775a1.694 1.694 0 0 0-.735-.144 2.49 2.49 0 0 0-.434.037l-.019.003h-.018c-.163 0-.508.447-.715.847a30.375 30.375 0 0 0-.632 1.766l-.782 3.83-.185 1.016c-.02.108-.115.183-.213.175a.21.21 0 0 1-.187-.207L0 6.393C.042 2.823.212.673.214.653a.232.232 0 0 1 .02-.076C.406.19.656.043.684.027a.19.19 0 0 1 .058-.02z" />
      </clipPath>
      <clipPath id="9gbw4w2elq">
        <path d="M9.234 0v1.315L4.738 1.22s.446 5.124.56 6.083l-.165 2.096H3.146l.42-8.042L0 1.438V.068L9.234 0z" />
      </clipPath>
      <clipPath id="bftmf0auxr">
        <path d="M9.246 0v10.287H0V0h9.246z" />
      </clipPath>
      <clipPath id="g45a3c1m3s">
        <path d="M.917 0c.446 0 1.157.433 2.132 1.298.61.583 1.223 1.172 1.834 1.766l1.339-1.47C6.932.876 7.477.517 7.858.517c.925 0 1.388 1.204 1.388 3.61 0 4.108-.52 6.16-1.562 6.16-.48 0-.719-.385-.719-1.156 0-.49.087-1.23.26-2.22.174-.99.26-1.735.26-2.236 0-.896-.165-1.344-.495-1.344-.264 0-.81.513-1.636 1.54-.826 1.026-1.397 1.54-1.71 1.54-.38 0-.57-.225-.57-.673 0-.25.086-.482.26-.696.173-.213.26-.414.26-.601 0-.167-.223-.454-.67-.86-.346.5-.586 1.324-.718 2.47-.083.865-.173 1.73-.273 2.595-.083.47-.306.703-.669.703-.298 0-.554-.13-.768-.39-.182-.22-.273-.433-.273-.641 0-.427.04-1.063.124-1.907.082-.845.124-1.475.124-1.892 0-.844-.058-1.558-.174-2.142 0-.031-.066-.291-.198-.781A3.914 3.914 0 0 1 0 .813C0 .646.087.469.26.28A.857.857 0 0 1 .917 0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#j9idu7uz6a)" transform="translate(-33 -985)">
      <g clipPath="url(#redq5rtzzb)" transform="translate(56.691 985.328)">
        <path fill="currentColor" d="M0 0h8.45v9.331H0V0z" />
      </g>
      <g clipPath="url(#tv340akicc)" transform="translate(67.573 985.104)">
        <path fill="currentColor" d="M0 0h5.072v9.743H0V0z" />
      </g>
      <g clipPath="url(#3u4q4d05od)" transform="translate(81.535 985.324)">
        <path fill="currentColor" d="M0 0h7.614v9.336H0V0z" />
      </g>
      <g clipPath="url(#e1jdalf5ae)" transform="translate(96.603 985.17)">
        <path fill="currentColor" d="M0 0h5.96v9.65H0V0z" />
      </g>
      <g clipPath="url(#34m83osdbf)" transform="translate(105.314 985.184)">
        <path fill="currentColor" d="M0 0h4.205v9.476H0V0z" />
      </g>
      <g clipPath="url(#j3lsvqxh6g)" transform="translate(113.076 985.18)">
        <path fill="currentColor" d="M0 0h2.065v2.22H0V0z" />
      </g>
      <g clipPath="url(#aeh5jv2m1h)" transform="translate(112.27 988.295)">
        <path fill="currentColor" d="M0 0h3.84v6.364H0V0z" />
      </g>
      <g clipPath="url(#p64870v7ki)" transform="translate(118.86 985.094)">
        <path fill="currentColor" d="M0 0h7.96v9.566H0V0z" />
      </g>
      <g clipPath="url(#v33s2272bj)" transform="translate(137.937 985.242)">
        <path fill="currentColor" d="M0 0h10.217v9.618H0V0z" />
      </g>
      <g clipPath="url(#ia4edqxsyk)" transform="translate(43.99 985.28)">
        <path fill="currentColor" d="M0 0h9.833v9.461H0V0z" />
      </g>
      <g clipPath="url(#4cfhpkjdwl)" transform="translate(157.686 985.287)">
        <g clipPath="url(#runhh3nt8m)">
          <path fill="currentColor" d="M0 0h11.314v15.713H0V0z" />
        </g>
      </g>
      <g clipPath="url(#foz7l5t27n)" transform="translate(75.242 987.823)">
        <path fill="currentColor" d="M0 0h2.858v6.915H0V0z" />
      </g>
      <g clipPath="url(#7hjisfeeqo)" transform="translate(76.518 985.2)">
        <path fill="currentColor" d="M0 0h1.976v1.876H0V0z" />
      </g>
      <g clipPath="url(#5affxh6xop)" transform="translate(151.806 985.087)">
        <path fill="currentColor" d="M0 0h6.453v9.927H0V0z" />
      </g>
      <g clipPath="url(#9gbw4w2elq)" transform="translate(127.852 985.229)">
        <path fill="currentColor" d="M0 0h9.234v9.398H0V0z" />
      </g>
      <g clipPath="url(#bftmf0auxr)" transform="translate(33 985)">
        <g clipPath="url(#g45a3c1m3s)">
          <path fill="currentColor" d="M0 0h9.246v10.287H0V0z" />
        </g>
      </g>
    </g>
  </svg>
);

MartinStiftungMeilen.propTypes = { className: PropTypes.string.isRequired };

export default MartinStiftungMeilen;
