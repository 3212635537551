import React from 'react';
import PropTypes from 'prop-types';

const Riedhof = ({ className }) => (
  <svg viewBox="0 0 135 23" className={className}>
    <defs>
      <clipPath id="vzn5j3njua">
        <path d="M1024 0v1307H0V0h1024z" />
      </clipPath>
      <clipPath id="d3tcgxdi0b">
        <path d="M5.671 0c3.181 0 5.256.532 6.64 1.861 1.106.93 1.798 2.526 1.798 4.255 0 2.833-1.818 4.793-4.07 5.638l-.023.008.017.006c1.673.592 2.715 2.154 3.246 4.452.691 3.057 1.245 5.185 1.798 6.115h-3.043c-.415-.664-.83-2.526-1.522-5.318-.691-3.057-1.936-4.254-4.564-4.254H3.18v9.572H.138L0 .4C1.522.133 3.735 0 5.671 0zm.277 2.127c-1.383 0-2.49.133-2.905.266v8.11h2.905c3.181 0 5.256-1.728 5.256-4.254 0-2.925-2.213-4.122-5.256-4.122z" />
      </clipPath>
      <clipPath id="w7gtwbonbc">
        <path d="M3.043 0v22.202H0V0h3.043z" />
      </clipPath>
      <clipPath id="srol6f6fsd">
        <path d="M12.449 0v2.393H2.905V9.44h9.129v2.26H3.043v7.977h9.959v2.526H0V0h12.449z" />
      </clipPath>
      <clipPath id="lwrswrnh8e">
        <path d="M6.363 0c4.288 0 7.33.93 9.405 2.792 2.075 1.861 3.32 4.387 3.32 7.977 0 3.59-1.106 6.647-3.32 8.641-2.213 2.128-5.67 3.191-10.235 3.191-2.075 0-3.873-.133-5.395-.266L0 .4C1.798.133 4.011 0 6.363 0zm0 2.127c-1.522 0-2.767.133-3.458.266v17.55c.691.132 1.798.132 3.043.132 6.362 0 9.82-3.457 9.82-9.44 0-5.184-3.043-8.508-9.405-8.508z" />
      </clipPath>
      <clipPath id="pqrx2hu6pf">
        <path d="M3.043 0v9.306h11.204V0h3.043v22.202h-3.043v-10.37H3.043v10.37H0V0h3.043z" />
      </clipPath>
      <clipPath id="sy7agzq04g">
        <path d="M10.789 0c6.363.133 10.374 4.786 10.374 11.3 0 7.579-4.841 11.7-10.789 11.7C4.288 23 0 18.48 0 11.7 0 4.652 4.565 0 10.789 0zm-7.47 11.7c0 4.786 2.629 9.04 7.332 9.04 4.702 0 7.469-4.122 7.469-9.174 0-4.387-2.352-9.04-7.331-9.04-4.98-.133-7.47 4.254-7.47 9.173z" />
      </clipPath>
      <clipPath id="h0rgssn9jh">
        <path d="M12.587 0v2.393H3.043v7.312h8.714v2.393H3.043v10.104H0L.138 0h12.45z" />
      </clipPath>
    </defs>
    <g clipPath="url(#vzn5j3njua)" transform="translate(-33 -988)">
      <g clipPath="url(#d3tcgxdi0b)" transform="translate(33 988.399)">
        <path fill="currentColor" d="M0 0h15.077v22.335H0V0z" />
      </g>
      <g clipPath="url(#w7gtwbonbc)" transform="translate(53.471 988.532)">
        <path fill="currentColor" d="M0 0h3.043v22.202H0V0z" />
      </g>
      <g clipPath="url(#srol6f6fsd)" transform="translate(63.43 988.532)">
        <path fill="currentColor" d="M0 0h13.002v22.202H0V0z" />
      </g>
      <g clipPath="url(#lwrswrnh8e)" transform="translate(82.242 988.399)">
        <path fill="currentColor" d="M0 0h19.088v22.601H0V0z" />
      </g>
      <g clipPath="url(#pqrx2hu6pf)" transform="translate(106.31 988.532)">
        <path fill="currentColor" d="M0 0h17.29v22.202H0V0z" />
      </g>
      <g clipPath="url(#sy7agzq04g)" transform="translate(128.856 988)">
        <path fill="currentColor" d="M0 0h21.163v23H0V0z" />
      </g>
      <g clipPath="url(#h0rgssn9jh)" transform="translate(155.413 988.532)">
        <path fill="currentColor" d="M0 0h12.587v22.202H0V0z" />
      </g>
    </g>
  </svg>
);

Riedhof.propTypes = { className: PropTypes.string.isRequired };

export default Riedhof;
