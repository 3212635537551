/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Icon from './Icon';
import ArrowIcon from './ArrowIcon';
import useStyles, { variants } from './styles';

const Item = ({ id, location, phrase, texts, info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles(isOpen);
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  return (
    <div>
      <button type="button" onClick={handleClick} className={classes.head}>
        <div className={classes.imageWrap}>
          <Icon svgIndex={id} />
        </div>
        <p className={classes.locationText}>{location}</p>
        <p className={classes.infoText}>{info}</p>
        <p className={classes.phrase}>{phrase}</p>
        <div className={classes.iconWrap}>
          <ArrowIcon isOpen={isOpen} iconClassName={classes.icon} />
        </div>
      </button>
      <motion.div animate={isOpen ? 'open' : 'close'} variants={variants} initial={false} className={classes.collapseWrap}>
        {texts.map((text, key) => (
          <p key={key} className={classes.text}>
            {text}
          </p>
        ))}
      </motion.div>
    </div>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  phrase: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  info: PropTypes.string.isRequired,
};

export default Item;
export { default as Head } from '@/components/Head';
