import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, fonts, components, colors }) => ({
  container: {
    margin: [0, spacing * 2],
    paddingBottom: components.curtain.height * 2,
  },
  title: {
    ...fonts.title,
    padding: [3 * spacing, 0, 2 * spacing],
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing * 1.5,
  },
  '@media (max-width: 799px)': {
    container: {
      margin: 0,
    },
    title: {
      padding: [3 * spacing, spacing],
    },
  },
}));
