import React from 'react';
import PropTypes from 'prop-types';

const TertianumZurHeimat = ({ className }) => (
  <svg viewBox="0 0 134 17" className={className}>
    <defs>
      <clipPath id="yc0yptf3oa">
        <path d="M1024 0v1307H0V0h1024z" />
      </clipPath>
      <clipPath id="oe2iztpk1b">
        <path d="M12.67 0v2.528H7.913v14.11h-3.28l.001-14.11H0V0h12.67z" />
      </clipPath>
      <clipPath id="pljun7rvzc">
        <path d="m9.885 0-.028 2.36H3.28v4.647h5.447v2.36H3.28v4.888h6.705v2.384H0V0h9.885z" />
      </clipPath>
      <clipPath id="vv5wmy8a8d">
        <path d="M5.078 0c4.462 0 6.605 1.59 6.605 4.888 0 2.432-1.444 3.987-3.304 4.575l4.568 7.176h-3.52l-4.068-6.67H3.28v6.67H0V0h5.078zm.099 2.312H3.279v5.233h1.996c2.046 0 3.32-.76 3.32-2.759 0-1.806-1.224-2.474-3.418-2.474z" />
      </clipPath>
      <clipPath id="kfxd2v4tme">
        <path d="M12.67 0v2.528H7.913v14.11H4.634V2.529H0V0h12.67z" />
      </clipPath>
      <clipPath id="9lzadesryf">
        <path d="m4.166 0 6.078 13.388h.098c-.172-.819-.433-3.226-.433-6.236V0h2.983l-.035 16.639H8.592L2.59 3.299H2.49c.148.722.493 3.467.493 6.236v7.104H0V0h4.166z" />
      </clipPath>
      <clipPath id="nnehtf2aig">
        <path d="M3.279 0v11.028c0 2.336.936 3.492 3.056 3.492 2.095 0 3.081-1.156 3.082-3.492V0h3.278v11.149c0 3.395-2.194 5.851-6.36 5.851C2.12 17 0 14.616 0 11.149V0H3.28z" />
      </clipPath>
      <clipPath id="jd2w0rx1ih">
        <path d="M3.279 0v16.639H0V0H3.28z" />
      </clipPath>
      <clipPath id="f1lssa46yi">
        <path d="m10.652 0 6.653 16.639h-3.5L8.651 3.065 3.5 16.639H0L6.653 0h3.999z" />
      </clipPath>
      <clipPath id="5kaq2xctaj">
        <path d="M1.914 0C3.002 0 3.83.831 3.83 1.87c0 1.016-.827 1.87-1.915 1.87C.851 3.74 0 2.886 0 1.87 0 .831.85 0 1.914 0z" />
      </clipPath>
      <clipPath id="a80tr5ey5k">
        <path d="m5.547 0 3.106 11.63h.098L11.733 0h4.24l1.331 16.639H14.15l-.542-8.356-.037-.637c-.1-1.799-.155-3.502-.11-4.278h-.1l-2.91 11.537H6.83L3.773 3.368h-.099c.099.964.074 3.182-.049 5.204l-.493 8.067H0L1.356 0h4.19z" />
      </clipPath>
    </defs>
    <g clipPath="url(#yc0yptf3oa)" transform="translate(-34 -1131)">
      <g clipPath="url(#oe2iztpk1b)" transform="translate(34 1131)">
        <path fill="currentColor" d="M0 0h12.67v16.639H0V0z" />
      </g>
      <g clipPath="url(#pljun7rvzc)" transform="translate(49.228 1131)">
        <path fill="currentColor" d="M0 0h9.984v16.639H0V0z" />
      </g>
      <g clipPath="url(#vv5wmy8a8d)" transform="translate(62.388 1131)">
        <path fill="currentColor" d="M0 0h12.947v16.639H0V0z" />
      </g>
      <g clipPath="url(#kfxd2v4tme)" transform="translate(75.972 1131)">
        <path fill="currentColor" d="M0 0h12.67v16.639H0V0z" />
      </g>
      <g clipPath="url(#9lzadesryf)" transform="translate(117.258 1131)">
        <path fill="currentColor" d="M0 0h12.892v16.639H0V0z" />
      </g>
      <g clipPath="url(#nnehtf2aig)" transform="translate(134.3 1131)">
        <path fill="currentColor" d="M0 0h12.695v17H0V0z" />
      </g>
      <g clipPath="url(#jd2w0rx1ih)" transform="translate(91.64 1131)">
        <path fill="currentColor" d="M0 0h3.279v16.639H0V0z" />
      </g>
      <g clipPath="url(#f1lssa46yi)" transform="translate(97.417 1131)">
        <path fill="currentColor" d="M0 0h17.305v16.639H0V0z" />
      </g>
      <g clipPath="url(#5kaq2xctaj)" transform="translate(104.155 1141.64)">
        <path fill="currentColor" d="M0 0h3.829v3.74H0V0z" />
      </g>
      <g clipPath="url(#a80tr5ey5k)" transform="translate(150.696 1131)">
        <path fill="currentColor" d="M0 0h17.304v16.639H0V0z" />
      </g>
    </g>
  </svg>
);

TertianumZurHeimat.propTypes = { className: PropTypes.string.isRequired };

export default TertianumZurHeimat;
