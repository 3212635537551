import { createUseStyles } from 'react-jss';

const transition = { duration: 0.5 };
export const variants = {
  open: { height: 'auto', opacity: 1, transition },
  close: { height: 0, opacity: 0, transition },
};

export default createUseStyles(({ spacing, fonts, components, colors }) => ({
  head: {
    display: 'grid',
    gridTemplateColumns: '138px auto auto',
    gridTemplateRows: 'auto 1fr',
    columnGap: spacing * 1.5,
    width: '100%',
    minHeight: 126,
    position: 'relative',
    backgroundColor: colors.white,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    boxShadow: '0 2px 23px 0 rgba(204, 204, 204, 0.5)',
    padding: spacing * 1.5,
    color: (isActive) => (isActive ? colors.black : colors.grey86),
    '&:hover $locationText, &:hover $infoText, &:hover $phrase': {
      color: colors.blue,
    },
    '&:hover $icon': {
      backgroundColor: colors.blue,
    },
    '&:hover $imageWrap': {
      color: colors.black,
    },
  },
  imageWrap: {
    gridColumn: '1/1',
    gridRow: '1/3',
    width: '100%',
    height: '100%',
  },
  iconWrap: {
    position: 'absolute',
    right: spacing * 1.5,
    bottom: spacing * 1.5,
    width: 22,
    height: 12,
  },
  icon: {},
  locationText: {
    gridColumn: '2/3',
    gridRow: '1/2',
    borderColor: (isActive) => (isActive ? colors.black : colors.grey86),
    ...fonts.subtitle,
    color: 'inherit',
    textAlign: 'left',
    transitionProperty: 'border-color color',
    transitionDuration: '0.5s',
    margin: 0,
  },
  infoText: {
    gridColumn: '3/4',
    gridRow: '1/2',
    justifySelf: 'end',
    ...fonts.subtitle,
    color: 'inherit',
    transition: 'color 0.5s',
    margin: 0,
  },
  phrase: {
    gridColumn: '2/4',
    gridRow: '2/3',
    justifySelf: 'start',
    alignSelf: 'center',
    ...fonts.title,
    textAlign: 'left',
    color: 'inherit',
    transition: 'color 0.5s',
    padding: ['0.3em', spacing * 3, '0.3em', 0],
  },
  collapseWrap: {
    marginLeft: 160,
    overflow: 'hidden',
  },
  text: {
    ...fonts.subtitle,
    margin: ['1em', 0],
  },
  '@media (max-width: 799px)': {
    head: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '68px auto auto',
      padding: [spacing, spacing * 1.5],
    },
    imageWrap: {
      gridColumn: '1/2',
      gridRow: '1/2',
      width: 77,
      height: 'calc(100% - 12px)',
    },
    iconWrap: {
      bottom: spacing,
    },
    locationText: {
      gridColumn: '1/2',
      gridRow: '1/2',
      height: '100%',
      width: 'calc(100% - 85)',
      borderBottom: [1, 'solid'],
      ...fonts.subtitleSmall,
      color: colors.black,
      paddingLeft: 85,
    },
    infoText: {
      gridColumn: '1/2',
      gridRow: '3/4',
      justifySelf: 'start',
      textAlign: 'left',
      ...fonts.subtitleSmall,
      color: 'inherit',
      paddingRight: spacing * 3,
    },
    phrase: {
      gridColumn: '1/2',
      gridRow: '2/3',
      color: colors.black,
      padding: [spacing, 0, spacing * 0.5],
    },
    collapseWrap: {
      margin: 0,
    },
    text: {
      ...fonts.title,
      margin: ['1em', spacing * 1.5],
    },
  },
}));
