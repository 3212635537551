import { createUseStyles } from 'react-jss';

export default createUseStyles(({ colors }) => ({
  container: {
    position: 'relative',
    width: 22,
    height: 12,
  },
  left: {
    position: 'absolute',
    left: -2,
    bottom: 5,
    width: 15,
    height: 1.5,
    backgroundColor: (isActive) => (isActive ? colors.black : colors.grey86),
    transform: 'rotate(45deg)',
    transition: 'background-color 0.5s',
  },
  right: {
    position: 'absolute',
    left: 8,
    bottom: 5,
    width: 15,
    height: 1.5,
    backgroundColor: (isActive) => (isActive ? colors.black : colors.grey86),
    transform: 'rotate(135deg)',
    transition: 'background-color 0.5s',
  },
}));

export const leftVariants = {
  open: {
    rotate: ['45deg', '90deg', '135deg'],
    left: [-2, 3, -2.0001],
    borderRadius: [0, 1, 0.0001],
  },
  close: {
    rotate: ['135deg', '90deg', '45deg'],
    left: [-2.0001, 3, -2],
    borderRadius: [0.0001, 1, 0],
  },
};

export const rightVariants = {
  open: {
    rotate: ['135deg', '90deg', '45deg'],
    left: [8, 3, 8.0001],
    borderRadius: [0, 1, 0.0001],
  },
  close: {
    rotate: ['45deg', '90deg', '135deg'],
    left: [8.0001, 3, 8],
    borderRadius: [0.0001, 1, 0],
  },
};

export const transition = {
  type: 'keyframes',
  times: [0, 0.5, 1],
  duration: 0.5,
};
