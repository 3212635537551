import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { mapping } from './utils';
import useStyles from './styles';

const Icon = ({ svgIndex }) => {
  const classes = useStyles();
  const [SvgIcon, className] = useMemo(() => mapping[svgIndex] || [], [svgIndex]);

  if (!SvgIcon) return null;

  return <SvgIcon className={classes[className]} />;
};

Icon.propTypes = { svgIndex: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]).isRequired };

export default Icon;
