import RegionalesAltersheimUnteresAaretal from '@/icons/references/RegionalesAltersheimUnteresAaretal';
import StiftungLiebenau from '@/icons/references/StiftungLiebenau';
import SunnezielMeggen from '@/icons/references/SunnezielMeggen';
import PflegheimStOtmar from '@/icons/references/PflegheimStOtmar';
import MartinStiftungMeilen from '@/icons/references/MartinStiftungMeilen';
import Riedhof from '@/icons/references/Riedhof';
import TertianumZurHeimat from '@/icons/references/TertianumZurHeimat';

export const mapping = [
  [RegionalesAltersheimUnteresAaretal, 'regionalesAltersheimUnteresAaretal'],
  [StiftungLiebenau, 'common'],
  [SunnezielMeggen, 'sunnezielMeggen'],
  [PflegheimStOtmar, 'pflegheimStOtmar'],
  [MartinStiftungMeilen, 'martinStiftungMeilen'],
  [Riedhof, 'riedhof'],
  [TertianumZurHeimat, 'tertianumZurHeimat'],
];
