import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import PropTypes from 'prop-types';

import useStyles, { leftVariants, rightVariants, transition } from './styles';

const ArrowIcon = ({ isOpen, iconClassName }) => {
  const classes = useStyles(isOpen);

  return (
    <div className={classes.container}>
      <motion.div
        className={cn(classes.left, iconClassName)}
        animate={isOpen ? 'open' : 'close'}
        variants={leftVariants}
        transition={transition}
        initial={isOpen ? 'open' : 'close'}
      />
      <motion.div
        className={cn(classes.right, iconClassName)}
        animate={isOpen ? 'open' : 'close'}
        variants={rightVariants}
        transition={transition}
        initial={isOpen ? 'open' : 'close'}
      />
    </div>
  );
};

ArrowIcon.defaultProps = { isOpen: false };
ArrowIcon.propTypes = {
  isOpen: PropTypes.bool,
  iconClassName: PropTypes.string.isRequired,
};

export default ArrowIcon;
