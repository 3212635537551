import React from 'react';
import PropTypes from 'prop-types';

const PflegheimStOtmar = ({ className }) => (
  <svg viewBox="0 0 126 40" className={className}>
    <defs>
      <clipPath id="3s7g8ykyga">
        <path d="M1024 0v1307H0V0h1024z" />
      </clipPath>
      <clipPath id="61wzyx7vkb">
        <path d="M39.044 0c1.51-.01 1.032.822 1.519 2.424.287 1 .218 1.925.157 3-.121 2.15-1.422 11.733-12.814 19.145-2.852.654-5.679 1.083-8.618.987-3.738-.069-7.053-1.402-10.38-2.623-2.394-1.971-4.415-4.244-5.989-6.98a23.878 23.878 0 0 1-2.286-5.547C.232 8.88.168 7.394.003 5.78c-.038-.687.26-2.21.348-2.485l.586-.348c1.074 2.111.868 4 2.178 6.023 1.063 1.712 2.254 3.778 3.876 5.038.834.663 4.823 2.839 5.684 3.275 1.57.685 7.96.62 9.529.793 5.753-1.246 11.204-4.404 14.373-9.522 1.094-1.702 1.303-3.614 2.022-5.529.385-.925.334-2.012.445-3.026z" />
      </clipPath>
      <clipPath id="gltr46k6sc">
        <path d="M16.937.024C18.725-.155 23.56.7 24.114 1.03c2.355 1.495 3.85 2.279 6.164 4.631 1.246 1.266 1.839 3.025 2.212 4.76.362 1.685.557 2.992.054 4.658-.153.413-.34.662-.493 1.075a46.167 46.167 0 0 1-.843 2.81c-.527 1.395-1.396 2.483-2.37 3.558-1.763 1.903-3.831 3.18-6.206 4.317-2.092 1.007-4.173 1.423-6.538 1.487-2.093.037-3.999-.173-5.905-.867-1.86-.634-3.249-1.648-4.674-2.826-1.413-1.285-2.427-2.795-3.547-4.317a13.637 13.637 0 0 1-1.155-2.17C.317 16.8.167 15.446 0 14.03l.454-.2c.13.768 1.01 3.743 1.54 4.285 1.096 1.25 2.022 2.588 3.54 3.4 1.72.942 8.54 2.445 10.681 2.465.377-.012 4.846-2.368 6.243-4.365.2-.355.505-.697.704-1.052 1.033-1.607 1.595-3.32 1.44-5.327-.178-1.31-.474-2.525-.935-3.706-.365-1.061-2.582-4.801-3.349-5.638-.435-.425-2.943-1.991-3.719-2.238-.836-.2-1.494-.607-1.476-1.169.894-.331.791-.412 1.814-.46z" />
      </clipPath>
      <clipPath id="in0x86gezd">
        <path d="m8.826 0 .156.006c1.912.258 3.606 1.204 4.979 2.494.905.812 1.601 1.791 2.246 2.812.52.865.99 1.771 1.105 2.875.084.532.126 1.011.159 1.585.104.77-.123 1.553-.3 2.293-.319 1.2-.764 2.243-1.615 3.099-.86.95-1.939 1.732-2.966 2.474-.353.199-.79.293-1.183.44-.841.334-1.703.43-2.658.514-.769.105-1.568.065-2.296-.205-1.372-.436-2.587-.997-3.617-1.965-.738-.604-1.216-1.416-1.748-2.187C-.036 12.402.2 10.244.01 8.087-.085 6.795.506 5.72 1.179 4.75c.674-.97 1.732-1.567 2.666-2.319C4.925 1.648 5.975.963 7.3.676c.145-.032.249-.115.3-.157.457-.282.913-.565 1.381-.513z" />
      </clipPath>
      <clipPath id="dhby5pt5ge">
        <path d="M2.976 0c1.92 0 2.505.195 3.139.554 1.057.589 1.626 1.633 1.626 2.939 0 1.501-.715 2.743-1.951 3.33-.52.244-1.009.375-2.26.375H2.276v4.179H0V0h2.976zm.749 1.844H2.277v3.493h1.187c.927 0 1.204-.114 1.512-.587.196-.31.277-.67.277-1.208 0-1.06-.569-1.698-1.528-1.698z" />
      </clipPath>
      <clipPath id="kd0mbx6o5f">
        <path d="M3.4 0c.715 0 1.3.147 1.967.523l-.7 1.354c-.471-.228-.634-.277-.894-.277-.553 0-.91.343-.91.946v1.176h2.113l-.553 1.534H2.88v6.84H.749v-6.84H0V3.722h.797a8.33 8.33 0 0 1-.08-1.126C.716.947 1.69 0 3.398 0z" />
      </clipPath>
      <clipPath id="peon47lc2g">
        <path d="M2.228 0c.081.457.114.996.114 2.171V9.08c.004 1.081.029 1.26.146 1.466.082.147.26.228.44.228.08 0 .13 0 .244-.032l.373 1.305c-.373.148-.83.229-1.3.229-.928 0-1.675-.44-1.936-1.143-.152-.397-.19-.652-.195-1.664V3.02C.113 1.926.08 1.257 0 .506L2.228 0z" />
      </clipPath>
      <clipPath id="q8ssrjfdrh">
        <path d="M3.66 0c.959 0 1.87.327 2.406.882.764.783 1.106 1.91 1.106 3.656v.506H2.358v.066c0 1.288.634 2.024 1.757 2.024.748 0 1.447-.278 2.114-.833l.845 1.306c-.96.783-1.968 1.159-3.138 1.159C1.545 8.766 0 7.068 0 4.44c0-1.502.309-2.497 1.04-3.313C1.725.359 2.554 0 3.66 0zm.032 1.6c-.83 0-1.301.653-1.301 1.812v.032h2.52v-.098c0-.62-.065-.946-.26-1.257-.211-.326-.52-.49-.96-.49z" />
      </clipPath>
      <clipPath id="4fzqogzlai">
        <path d="m7.985 0 .91 1.388c-.471.44-.926.62-1.528.62-.211 0-.293-.017-.57-.05.326.393.49.817.49 1.372 0 1.436-1.302 2.48-3.075 2.48-.097 0-.163 0-.325-.016-.553.262-.862.458-.862.687 0 .114.13.195.358.195l1.17.017c1.269.015 1.936.211 2.521.75.488.458.716 1.029.716 1.78 0 .701-.211 1.24-.667 1.73-.732.783-1.984 1.077-3.285 1.077-1.187 0-2.407-.195-3.155-.898C.228 10.708 0 10.218 0 9.647c0-.457.113-.686.195-.849h2.017c-.065.18-.098.245-.098.539 0 .67.553 1.028 1.562 1.028.617 0 1.09-.082 1.382-.277.31-.196.52-.523.52-.85 0-.325-.163-.62-.39-.75-.228-.114-.537-.212-1.139-.245-.113 0-.422-.016-.926-.016-.976-.016-1.61-.114-2.001-.261a1.038 1.038 0 0 1-.471-.408 1.522 1.522 0 0 1-.163-.686c0-.31.08-.57.26-.767.244-.277.78-.523 1.269-.636C.813 5.159.227 4.39.227 3.15.228 1.437 1.61.327 3.74.327c.472 0 .895.049 1.496.211.602.148.749.18 1.025.18.602 0 1.22-.261 1.724-.718zm-4.26 1.926c-.83 0-1.302.457-1.302 1.24 0 .85.521 1.192 1.285 1.192.862 0 1.334-.424 1.334-1.191 0-.784-.489-1.24-1.317-1.24z" />
      </clipPath>
      <clipPath id="5tewez1yyj">
        <path d="M3.66 0c.959 0 1.87.327 2.406.882.764.783 1.106 1.91 1.106 3.656v.506H2.358v.066c0 1.288.635 2.024 1.757 2.024.748 0 1.447-.278 2.113-.833l.846 1.306c-.96.783-1.968 1.159-3.138 1.159C1.545 8.766 0 7.068 0 4.44c0-1.502.309-2.497 1.04-3.313C1.725.359 2.554 0 3.66 0zm.032 1.6c-.83 0-1.301.653-1.301 1.812v.032h2.52v-.098c0-.62-.065-.946-.26-1.257-.211-.326-.52-.49-.96-.49z" />
      </clipPath>
      <clipPath id="ivs8lj3idk">
        <path d="M2.228 0c.098.457.146 1.24.146 2.04v1.665c0 .261-.033.604-.033.702.57-.538 1.512-.897 2.358-.897 1.026 0 1.904.49 2.164 1.192.162.472.211.603.211 1.55v5.843h-2.13V6.481c0-.833-.228-1.143-.847-1.143-.535 0-1.235.326-1.723.767v5.99H.162V2.432C.162 1.65.097.865 0 .522L2.228 0z" />
      </clipPath>
      <clipPath id="eiwaw1uxwl">
        <path d="M3.66 0c.959 0 1.87.327 2.407.882.764.783 1.105 1.91 1.105 3.656v.506H2.36v.066c0 1.288.634 2.024 1.756 2.024.748 0 1.448-.278 2.115-.833l.845 1.306c-.96.783-1.968 1.159-3.14 1.159C1.546 8.766 0 7.068 0 4.44c0-1.502.309-2.497 1.04-3.313C1.725.359 2.554 0 3.66 0zm.032 1.6c-.83 0-1.301.653-1.301 1.812v.032h2.522v-.098c0-.62-.066-.946-.262-1.257-.211-.326-.52-.49-.96-.49z" />
      </clipPath>
      <clipPath id="ktii2s3n9m">
        <path d="M2.423 3.46v8.554H.243v-8.21l2.18-.343zM1.366 0c.732 0 1.317.604 1.317 1.355 0 .75-.602 1.355-1.35 1.355A1.346 1.346 0 0 1 0 1.355C0 .605.618 0 1.366 0z" />
      </clipPath>
      <clipPath id="udpucokqmn">
        <path d="M8.668 0c.65 0 1.268.195 1.643.523.472.407.618.897.618 2.04v5.941H8.815V2.987c0-.996-.114-1.191-.683-1.191-.407 0-.976.277-1.448.701v6.007H4.619V3.07c0-1.045-.147-1.29-.749-1.29-.406 0-.96.212-1.43.637v6.088H.309V2.677C.309 1.469.228.947 0 .538L1.968 0c.146.229.211.424.293.848C2.83.293 3.529 0 4.293 0c.683 0 1.252.229 1.691.686.115.113.164.179.325.408C7.074.31 7.757 0 8.67 0z" />
      </clipPath>
      <clipPath id="fjc9mq1zzo">
        <path d="M4.553 0c1.383 0 2.781.425 3.774 1.143L7.253 2.775c-1.025-.62-1.789-.865-2.634-.865-.927 0-1.578.538-1.578 1.306 0 .587.31.88 1.252 1.142l1.367.376c1.853.506 2.862 1.697 2.862 3.378 0 2.302-1.854 3.82-4.667 3.82A8.058 8.058 0 0 1 0 10.905l.845-1.78c1.058.556 1.968.915 3.107.915 1.301 0 1.984-.54 1.984-1.55 0-.735-.472-1.209-1.48-1.47l-1.252-.343C2.08 6.366 1.383 5.926.975 5.24a3.289 3.289 0 0 1-.47-1.73C.504 1.437 2.162 0 4.552 0z" />
      </clipPath>
      <clipPath id="cfm7haig1p">
        <path d="M3.123 0c-.081.768-.13 1.665-.13 2.514h1.951l-.553 1.453H2.993V8.21c0 1.06.195 1.34.944 1.34.194 0 .39-.05.78-.165l.276 1.306c-.634.26-1.204.376-1.773.376-1.106 0-2-.49-2.244-1.225-.12-.329-.143-.494-.146-1.11V3.967H0v-1.42h.83c0-.816 0-1.371.081-1.992L3.123 0z" />
      </clipPath>
      <clipPath id="gwlwoj79eq">
        <path d="M1.414 0c.797 0 1.448.637 1.448 1.42 0 .8-.65 1.453-1.431 1.453A1.44 1.44 0 0 1 0 1.42C0 .637.633 0 1.414 0z" />
      </clipPath>
      <clipPath id="yj7wtechfr">
        <path d="M4.895 0c3.009 0 4.863 2.204 4.863 5.763 0 2.562-.895 4.472-2.521 5.387-.683.375-1.317.522-2.26.522-1.708 0-2.814-.473-3.676-1.6C.44 8.963 0 7.526 0 5.862 0 3.41.943 1.453 2.553.555 3.22.196 4.05 0 4.895 0zm-.033 1.763c-.764 0-1.447.392-1.756.996-.39.735-.57 1.73-.57 3.102 0 1.959.343 3.166 1.01 3.672.357.261.812.391 1.349.391 1.578 0 2.244-1.207 2.244-4.096 0-1.16-.097-1.992-.292-2.628-.18-.572-.781-1.437-1.985-1.437z" />
      </clipPath>
      <clipPath id="umkc46sfds">
        <path d="M3.123 0c-.081.768-.13 1.665-.13 2.514h1.951l-.553 1.453H2.992V8.21c0 1.06.196 1.34.944 1.34.195 0 .39-.05.78-.165l.277 1.306c-.634.26-1.204.376-1.773.376-1.106 0-2-.49-2.244-1.225-.12-.329-.143-.494-.146-1.11V3.967H0v-1.42h.83c0-.816 0-1.371.081-1.992L3.123 0z" />
      </clipPath>
      <clipPath id="22pz1s8agt">
        <path d="M8.668 0c.65 0 1.27.196 1.643.523.472.408.618.898.618 2.04v5.941H8.815V2.988c0-.996-.114-1.192-.683-1.192-.407 0-.976.277-1.448.702v6.006H4.62V3.07c0-1.045-.146-1.29-.748-1.29-.407 0-.96.213-1.431.637v6.088H.309V2.677C.309 1.47.228.947 0 .538L1.968 0c.146.229.212.425.293.85C2.83.294 3.529 0 4.293 0c.683 0 1.253.229 1.692.686.114.114.163.18.325.408C7.075.31 7.757 0 8.668 0z" />
      </clipPath>
      <clipPath id="2rbhj4beiu">
        <path d="M3.805 0c1.4 0 2.359.523 2.684 1.453.114.343.147.604.147 1.502l-.05 2.808v.147c0 .897.147 1.24.78 1.713L6.213 8.962c-.504-.213-.959-.588-1.17-1.012-.31.294-.342.326-.505.44-.406.294-.992.457-1.675.457C1.008 8.847 0 7.9 0 6.236c0-1.96 1.35-2.873 4-2.873.163 0 .26 0 .488.016v-.343c0-.93-.178-1.24-.976-1.24-.698 0-1.512.342-2.406.946L.179 1.175a6.231 6.231 0 0 1 1.35-.686C2.34.147 3.04 0 3.804 0zm.65 4.881h-.097c-1.512 0-2.032.278-2.032 1.274 0 .652.405 1.093.96 1.093.405 0 .812-.212 1.137-.571l.033-1.796z" />
      </clipPath>
      <clipPath id="3v7ypiu84v">
        <path d="M4.245 0c.325 0 .471.032.813.18l-.601 1.926a1.23 1.23 0 0 0-.635-.164c-.488 0-.927.229-1.333.702v5.908H.309V2.971C.309 1.845.179.996 0 .523L1.952 0c.195.343.308.718.341 1.19.31-.423.83-.864 1.236-1.06.18-.082.472-.13.716-.13z" />
      </clipPath>
    </defs>
    <g clipPath="url(#3s7g8ykyga)" transform="translate(-34 -646)">
      <g clipPath="url(#61wzyx7vkb)" transform="translate(34 660.43)">
        <path fill="currentColor" d="M0 0h40.774v25.57H0V0z" />
      </g>
      <g clipPath="url(#gltr46k6sc)" transform="translate(36.807 646)">
        <path fill="currentColor" d="M0 0h32.848v28.33H0V0z" />
      </g>
      <g clipPath="url(#in0x86gezd)" transform="translate(40.443 648.709)">
        <path fill="currentColor" d="M0 0h17.497v18.645H0V0z" />
      </g>
      <g clipPath="url(#dhby5pt5ge)" transform="translate(81.656 652.054)">
        <path fill="currentColor" d="M0 0h7.74v11.377H0V0z" />
      </g>
      <g clipPath="url(#kd0mbx6o5f)" transform="translate(90.569 651.335)">
        <path fill="currentColor" d="M0 0h5.367v12.095H0V0z" />
      </g>
      <g clipPath="url(#peon47lc2g)" transform="translate(96.051 651.335)">
        <path fill="currentColor" d="M0 0h3.545v12.275H0V0z" />
      </g>
      <g clipPath="url(#q8ssrjfdrh)" transform="translate(100.327 654.91)">
        <path fill="currentColor" d="M0 0h7.172v8.766H0V0z" />
      </g>
      <g clipPath="url(#4fzqogzlai)" transform="translate(108.772 654.6)">
        <path fill="currentColor" d="M0 0h8.896v12.03H0V0z" />
      </g>
      <g clipPath="url(#5tewez1yyj)" transform="translate(118.092 654.91)">
        <path fill="currentColor" d="M0 0h7.172v8.766H0V0z" />
      </g>
      <g clipPath="url(#ivs8lj3idk)" transform="translate(126.96 651.335)">
        <path fill="currentColor" d="M0 0h7.074v12.095H0V0z" />
      </g>
      <g clipPath="url(#eiwaw1uxwl)" transform="translate(135.891 654.91)">
        <path fill="currentColor" d="M0 0h7.172v8.766H0V0z" />
      </g>
      <g clipPath="url(#ktii2s3n9m)" transform="translate(144.58 651.417)">
        <path fill="currentColor" d="M0 0h2.683v12.014H0V0z" />
      </g>
      <g clipPath="url(#udpucokqmn)" transform="translate(149.071 654.927)">
        <path fill="currentColor" d="M0 0h10.929v8.504H0V0z" />
      </g>
      <g clipPath="url(#fjc9mq1zzo)" transform="translate(80.745 669.946)">
        <path fill="currentColor" d="M0 0h8.522v11.933H0V0z" />
      </g>
      <g clipPath="url(#cfm7haig1p)" transform="translate(90.082 670.745)">
        <path fill="currentColor" d="M0 0h4.993v11.068H0V0z" />
      </g>
      <g clipPath="url(#gwlwoj79eq)" transform="translate(95.826 679.022)">
        <path fill="currentColor" d="M0 0h2.862v2.873H0V0z" />
      </g>
      <g clipPath="url(#yj7wtechfr)" transform="translate(100.184 670.141)">
        <path fill="currentColor" d="M0 0h9.758v11.672H0V0z" />
      </g>
      <g clipPath="url(#umkc46sfds)" transform="translate(110.968 670.745)">
        <path fill="currentColor" d="M0 0h4.993v11.068H0V0z" />
      </g>
      <g clipPath="url(#22pz1s8agt)" transform="translate(116.76 673.128)">
        <path fill="currentColor" d="M0 0h10.929v8.504H0V0z" />
      </g>
      <g clipPath="url(#2rbhj4beiu)" transform="translate(129.58 673.096)">
        <path fill="currentColor" d="M0 0h7.367v8.962H0V0z" />
      </g>
      <g clipPath="url(#3v7ypiu84v)" transform="translate(138.314 673.08)">
        <path fill="currentColor" d="M0 0h5.058v8.552H0V0z" />
      </g>
    </g>
  </svg>
);

PflegheimStOtmar.propTypes = { className: PropTypes.string.isRequired };

export default PflegheimStOtmar;
