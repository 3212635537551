import { createUseStyles } from 'react-jss';

const defaultStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  transition: 'color 0.5s',
  '@media (max-width: 799px)': {
    width: '100%',
    height: 'auto',
  },
};

export default createUseStyles({
  common: defaultStyles,
  regionalesAltersheimUnteresAaretal: {
    ...defaultStyles,
    maxWidth: 110,
  },
  sunnezielMeggen: defaultStyles,
  pflegheimStOtmar: defaultStyles,
  martinStiftungMeilen: defaultStyles,
  riedhof: defaultStyles,
  tertianumZurHeimat: defaultStyles,
  '@media (max-width: 799px)': {
    sunnezielMeggen: { marginTop: 2 },
    pflegheimStOtmar: { marginTop: 2 },
    martinStiftungMeilen: { marginTop: 7 },
    riedhof: { marginTop: 2.5 },
    tertianumZurHeimat: { marginTop: 5 },
  },
});
