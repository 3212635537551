import React from 'react';
import PropTypes from 'prop-types';

const StiftungLiebenau = ({ className }) => (
  <svg viewBox="0 0 73 29" className={className}>
    <defs>
      <clipPath id="uf2vdbk4ua">
        <path d="M320 0v1525H0V0h320z" />
      </clipPath>
      <clipPath id="gu4p3bzz4b">
        <path d="M2.995 0C3.58 0 4.15.108 5.26.409v1.127a7.401 7.401 0 0 0-2.046-.311c-.902 0-1.352.31-1.352.924 0 .455.22.695 1.023 1.091l1.109.552c1.095.54 1.558 1.224 1.558 2.328 0 1.632-1.108 2.567-3.032 2.567-.584 0-1.364-.132-2.52-.42V7.14c.864.192 1.595.289 2.24.289 1.023 0 1.534-.36 1.534-1.068 0-.455-.22-.732-.852-1.056l-1.327-.685C.535 4.068.097 3.41.097 2.412.097.864 1.144 0 2.995 0z" />
      </clipPath>
      <clipPath id="ljcne96a4c">
        <path d="M2.557 0v1.931H4.37v1.02H2.557v3.12c0 .708.231.936.974.936.256 0 .45-.012.84-.071v.958c-.938.241-1.303.313-1.68.313-1.4 0-1.912-.504-1.912-1.872V2.952H0v-1.02h.779V.623L2.557 0z" />
      </clipPath>
      <clipPath id="gpiq6ev9rd">
        <path d="M1.948 2.41v6.192H.17V2.411h1.778zM1.059 0C1.84 0 2.13.215 2.13.816c0 .599-.291.828-1.07.828C.291 1.644 0 1.415 0 .816 0 .228.292 0 1.06 0z" />
      </clipPath>
      <clipPath id="idv9k2bhse">
        <path d="M2.813 0c.365 0 .67.047 1.509.24v.912a7.28 7.28 0 0 0-.742-.037c-.767 0-1.022.229-1.022.937v1.055h1.35v1.021h-1.35v5.17H.78v-5.17H0v-1.02h.78v-1.14C.78.624 1.412 0 2.813 0z" />
      </clipPath>
      <clipPath id="1m89wze6nf">
        <path d="M2.557 0v1.931H4.37v1.02H2.557v3.12c0 .708.231.936.974.936.256 0 .45-.012.84-.071v.958c-.938.241-1.303.313-1.68.313-1.401 0-1.912-.504-1.912-1.872V2.952H0v-1.02h.779V.623L2.557 0z" />
      </clipPath>
      <clipPath id="ppdxbb5flg">
        <path d="M1.778 0v3.864c0 .888.292 1.2 1.144 1.2a4.89 4.89 0 0 0 1.01-.12V0h1.779v6.192H3.97v-.396c-.986.408-1.474.527-2.07.527-.585 0-1.17-.239-1.473-.611C.11 5.328 0 4.908 0 4.02V0h1.778z" />
      </clipPath>
      <clipPath id="68k2yeuh0h">
        <path d="M3.812 0c.597 0 1.168.24 1.472.612.318.384.427.817.427 1.692v4.02H3.934V2.458c0-.887-.305-1.199-1.145-1.199-.293 0-.609.036-1.01.12v4.943H0V.131h1.73v.397C2.85.108 3.263 0 3.811 0z" />
      </clipPath>
      <clipPath id="30aklwi7pi">
        <path d="M2.959 0c.475 0 .95.12 1.352.348V.084h1.728V6.48c0 1.103-.28 1.68-1.035 2.087-.51.276-1.23.42-2.07.42-.657 0-1.193-.084-2.483-.372V7.56a9.602 9.602 0 0 0 2.07.252c1.351 0 1.741-.311 1.741-1.404v-.503c-.938.383-1.327.492-1.73.492C.976 6.396 0 5.22 0 3.347 0 1.393 1.23 0 2.959 0zm.657 1.104c-1.131 0-1.778.755-1.778 2.064 0 1.26.573 1.943 1.62 1.943.28 0 .487-.023.804-.071V1.165c-.28-.048-.44-.06-.646-.06z" />
      </clipPath>
      <clipPath id="wtqkxyigvj">
        <path d="M1.84 0v7.271h3.018v1.2H0V0h1.84z" />
      </clipPath>
      <clipPath id="rafj3gubqk">
        <path d="M1.948 2.411v6.192H.171V2.411h1.777zM1.06 0c.78 0 1.073.215 1.073.816 0 .6-.293.828-1.073.828C.293 1.644 0 1.415 0 .816 0 .228.293 0 1.06 0z" />
      </clipPath>
      <clipPath id="jrsqdx9v2l">
        <path d="M3.008 0C4.31 0 5.175.576 5.516 1.68c.134.409.17.792.17 1.848h-3.92c0 1.272.548 1.751 2.008 1.751.463 0 .962-.035 1.62-.119v.9c-1.218.263-1.851.36-2.326.36C1.084 6.42 0 5.328 0 3.325 0 1.26 1.145 0 3.008 0zM2.91.936c-.793 0-1.145.504-1.145 1.62h2.179c0-1.2-.268-1.62-1.034-1.62z" />
      </clipPath>
      <clipPath id="x78nue0lwm">
        <path d="M1.778 0v3.457c.949-.372 1.314-.481 1.729-.481 1.546 0 2.532 1.188 2.532 3.048 0 2.003-1.424 3.36-3.519 3.36-.645 0-1.448-.132-2.52-.397V0h1.778zm.815 4.247c-.292 0-.499.025-.815.085v3.923c.22.025.365.037.51.037 1.243 0 1.913-.732 1.913-2.088 0-1.248-.585-1.957-1.608-1.957z" />
      </clipPath>
      <clipPath id="q6danpuu9n">
        <path d="M3.008 0c1.303 0 2.167.576 2.508 1.68.134.409.17.792.17 1.848h-3.92c0 1.272.549 1.751 2.009 1.751.462 0 .962-.035 1.62-.119v.9c-1.218.263-1.852.36-2.326.36C1.085 6.42 0 5.328 0 3.325 0 1.26 1.145 0 3.008 0zm-.097.936c-.793 0-1.145.504-1.145 1.62h2.18c0-1.2-.269-1.62-1.035-1.62z" />
      </clipPath>
      <clipPath id="xqn7mj7fho">
        <path d="M3.811 0c.597 0 1.17.24 1.473.612.317.384.427.816.427 1.691v4.02H3.933V2.46c0-.887-.304-1.2-1.144-1.2-.293 0-.61.037-1.01.122v4.942H0V.132h1.729v.396C2.849.108 3.264 0 3.81 0z" />
      </clipPath>
      <clipPath id="fqmg3faoip">
        <path d="M3.129 0c.718 0 1.327.168 1.68.48.39.336.537.805.537 1.74v4.092H3.69v-.396c-.951.383-1.316.48-1.777.48C.755 6.396 0 5.65 0 4.512c0-1.165.67-1.8 2.021-1.92.511-.048.998-.073 1.62-.073v-.515c0-.612-.316-.852-1.096-.852-.584 0-1.34.071-1.997.192V.421C1.766.12 2.532 0 3.128 0zm.511 3.42c-.571.024-.73.024-.998.06-.572.083-.876.384-.876.888 0 .588.366.888 1.071.888.255 0 .548-.036.803-.096V3.42z" />
      </clipPath>
      <clipPath id="oq9154iibq">
        <path d="M1.778 0v3.863c0 .888.291 1.2 1.144 1.2.292 0 .609-.036 1.01-.12V0h1.779v6.192H3.97v-.397c-.987.408-1.474.528-2.07.528-.585 0-1.17-.239-1.475-.612C.109 5.328 0 4.907 0 4.019V0h1.778z" />
      </clipPath>
      <clipPath id="u8fn7oqifr">
        <path d="m2.832 0 .032.034c.441.474.827 1.178 1.236 1.923.646 1.179 1.315 2.398 2.306 2.942 1.564.856 3.677-1.097 4.58-1.931l.042-.04a99.506 99.506 0 0 0 1.474-1.404l.07-.067.269-.262c.375-.366.7-.683 1.132-1.024l.049-.04.033.054c.125.201.294.535.41.78l.013.029-.02.026c-1.074 1.465-2.228 2.894-3.344 4.275-.728.9-1.48 1.831-2.202 2.763.083.431.175.87.264 1.294.347 1.652.706 3.362.71 5.239.009 4.35-1.867 7.563-5.15 8.82v5.499H2.718l.002-.057c.135-3.913-.207-6.68-1.078-8.707l-.028-.066.07-.01c2.468-.396 5.434-1.189 6.48-3.822a4.2 4.2 0 0 0 .255-1.353c.044-3.043-1.488-5.136-3.06-7.02-1.397-1.67-3.137-3.536-5.32-5.701L0 2.135l.039-.039C.879 1.254 2.013.51 2.793.024L2.833 0z" />
      </clipPath>
      <clipPath id="nj2hyj560s">
        <path d="m.544 0 .048.043C1.14.533 1.67 1.14 2.23 1.783c1.185 1.356 2.41 2.76 3.98 3.299 1.607.552 3.162-1.02 4.19-2.06.162-.164.314-.319.45-.448l.036-.033.038.03.196.171.234.209c.399.356.982.883 1.386 1.259l.24.225c.101.097.175.17.206.206l.045.052-.06.032c-1.67.895-3.43 2.208-5.087 3.798-1.419 1.363-2.758 2.965-2.952 5.446-.148 1.886.644 3.33 2.422 4.418 1.27.776 2.52 1.114 4.46 1.209l.034.002.014.03c1.24 2.608 1.303 6.201 1.138 8.757l-.004.051h-3.003l.012-.064c.225-1.328.613-2.52.99-3.673.165-.508.336-1.033.49-1.564-1.828-.653-3.722-1.464-5.224-2.938-1.203-1.182-2.609-2.865-2.796-5.417-.122-1.645.275-3.395.722-5.042.031-.113.071-.244.114-.381.126-.407.269-.868.245-1.123-.026-.288-.538-1.014-.877-1.494-.103-.147-.192-.272-.248-.36-.482-.739-.978-1.466-1.458-2.17C1.44 3.153.695 2.06.016.95L0 .923.014.895C.196.55.348.29.51.053L.544 0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#uf2vdbk4ua)" transform="translate(-12 -287)">
      <g clipPath="url(#gu4p3bzz4b)" transform="translate(12 295.894)">
        <path fill="currentColor" d="M0 0h5.552v8.687H0V0z" />
      </g>
      <g clipPath="url(#ljcne96a4c)" transform="translate(17.988 296.35)">
        <path fill="currentColor" d="M0 0h4.371v8.207H0V0z" />
      </g>
      <g clipPath="url(#gpiq6ev9rd)" transform="translate(23.173 295.871)">
        <path fill="currentColor" d="M0 0h2.13v8.602H0V0z" />
      </g>
      <g clipPath="url(#idv9k2bhse)" transform="translate(26.094 295.175)">
        <path fill="currentColor" d="M0 0h4.322v9.299H0V0z" />
      </g>
      <g clipPath="url(#1m89wze6nf)" transform="translate(30.511 296.35)">
        <path fill="currentColor" d="M0 0h4.371v8.207H0V0z" />
      </g>
      <g clipPath="url(#ppdxbb5flg)" transform="translate(35.77 298.282)">
        <path fill="currentColor" d="M0 0h5.71v6.323H0V0z" />
      </g>
      <g clipPath="url(#68k2yeuh0h)" transform="translate(43.023 298.15)">
        <path fill="currentColor" d="M0 0h5.71v6.323H0V0z" />
      </g>
      <g clipPath="url(#30aklwi7pi)" transform="translate(49.9 298.198)">
        <path fill="currentColor" d="M0 0h6.039v8.987H0V0z" />
      </g>
      <g clipPath="url(#wtqkxyigvj)" transform="translate(12.365 307.397)">
        <path fill="currentColor" d="M0 0h4.858v8.472H0V0z" />
      </g>
      <g clipPath="url(#rafj3gubqk)" transform="translate(17.903 307.266)">
        <path fill="currentColor" d="M0 0h2.132v8.603H0V0z" />
      </g>
      <g clipPath="url(#jrsqdx9v2l)" transform="translate(21.056 309.557)">
        <path fill="currentColor" d="M0 0h5.686v6.42H0V0z" />
      </g>
      <g clipPath="url(#x78nue0lwm)" transform="translate(27.885 306.58)">
        <path fill="currentColor" d="M0 0h6.04v9.384H0V0z" />
      </g>
      <g clipPath="url(#q6danpuu9n)" transform="translate(34.773 309.557)">
        <path fill="currentColor" d="M0 0h5.686v6.42H0V0z" />
      </g>
      <g clipPath="url(#xqn7mj7fho)" transform="translate(41.639 309.545)">
        <path fill="currentColor" d="M0 0h5.71v6.323H0V0z" />
      </g>
      <g clipPath="url(#fqmg3faoip)" transform="translate(48.443 309.557)">
        <path fill="currentColor" d="M0 0h5.346v6.396H0V0z" />
      </g>
      <g clipPath="url(#oq9154iibq)" transform="translate(55.259 309.677)">
        <path fill="currentColor" d="M0 0h5.71v6.323H0V0z" />
      </g>
      <g clipPath="url(#u8fn7oqifr)" transform="translate(70.522 287)">
        <path fill="currentColor" d="M0 0h14.478v28.91H0V0z" />
      </g>
      <g clipPath="url(#nj2hyj560s)" transform="translate(58.784 287.474)">
        <path fill="currentColor" d="M0 0h13.266v28.436H0V0z" />
      </g>
    </g>
  </svg>
);

StiftungLiebenau.propTypes = { className: PropTypes.string.isRequired };

export default StiftungLiebenau;
